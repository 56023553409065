import { jsPDF } from "jspdf";

import { elementToImage } from "./images";

export const openPDF = (pdf) => {
    const string = pdf.output("datauristring");
    const iframe2 =
        "<iframe width='100%' height='100%' src='" + string + "'></iframe>";
    const x = window.open();
    x.document.open();
    x.document.write(iframe2);
    x.document.close();
};

export const downLoadPDF = (pdf, filename) => {
    pdf.save(`${filename}.pdf`);
};

export const elementToPDF = async (element) => {
    const imgData = await elementToImage(element);
    const pdf = new jsPDF({
        orientation: "p",
        unit: "pt",
        format: [imgData.width, imgData.height]
    });
    const imgProps = pdf.getImageProperties(imgData.image);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData.image, "PNG", 0, 0, pdfWidth, pdfHeight);
    return pdf;
};
